import { render, staticRenderFns } from "./VideosListItemtest.vue?vue&type=template&id=ed6e781a&scoped=true&"
import script from "./VideosListItemtest.vue?vue&type=script&lang=js&"
export * from "./VideosListItemtest.vue?vue&type=script&lang=js&"
import style0 from "./VideosListItemtest.vue?vue&type=style&index=0&id=ed6e781a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed6e781a",
  null
  
)

export default component.exports