var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":_vm.videosItem.infoType === 'audio'
      ? '/mobVideoCenterAudioDel?audioId=' + _vm.videosItem.infoId
      : _vm.videosItem.infoType === 'article'
      ? '/mobVideoCenterArticleDel?articleId=' + _vm.videosItem.infoId
      : '/mobVideoCenterDel?videoId=' + _vm.videosItem.infoId}},[_c('div',{staticClass:"videos-item",on:{"mouseover":function($event){return _vm.mouseOver()},"mouseleave":function($event){return _vm.mouseLeave()}}},[_c('div',{staticClass:"video"},[_c('img',{style:({
          height: _vm.types[_vm.videosItem.infoType] != '视频' ? 'unset' : 100 + '%',
          'margin-top':
            _vm.types[_vm.videosItem.infoType] != '视频' ? 12 + 'px' : 'unset',
        }),attrs:{"src":_vm.videosItem.backImg ? _vm.videosItem.backImg : _vm.imgbg[_vm.videosItem.infoType],"alt":""}}),(_vm.types[_vm.videosItem.infoType] != '文章')?_c('div',{staticClass:"videotime"},[_c('span',{staticClass:"time"},[_vm._v(_vm._s(_vm.duration))])]):_vm._e(),_c('div',{class:{ mask: _vm.isactive }}),_c('div',{class:{ maskicon: _vm.isactive }},[_c('img',{style:({ display: this.isactive ? 'block' : 'none' }),attrs:{"src":_vm.imgstatus}})])]),_c('el-row',{staticStyle:{"margin":"0 13px","margin-top":"10px","border-bottom":"1px solid #F5F5F5"}},[_c('el-row',{staticStyle:{"margin-bottom":"2px"}},[_c('el-col',[_c('div',{staticClass:"videos-info"},[_vm._v(_vm._s(_vm.videosItem.title))])])],1),_c('el-row',{staticStyle:{"margin-bottom":"12px"}},[_c('el-col',{attrs:{"span":20}},[_c('span',{staticClass:"frequency"},[_vm._v(_vm._s(_vm.videosItem.browse)+"次播放")])]),_c('el-col',{attrs:{"span":4}},[_c('span',{staticClass:"type",style:(_vm.tycolor)},[_vm._v(_vm._s(_vm.types[_vm.videosItem.infoType]))])])],1)],1),_c('el-row',{staticStyle:{"margin":"0 13px","margin-top":"10px"},attrs:{"type":"flex"}},[_c('el-col',{staticStyle:{"display":"flex","align-item":"center"}},[_c('div',[_c('el-avatar',{attrs:{"size":24,"src":_vm.videosItem.avatar}})],1),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.videosItem.doctorName))])]),_c('el-col',{staticStyle:{"text-align":"right"}},[_c('span',{staticClass:"position"},[_vm._v(_vm._s(_vm.videosItem.doctorTitle.length > 8 ? _vm.videosItem.doctorTitle.slice(0, 7) + "..." : _vm.videosItem.doctorTitle))])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }