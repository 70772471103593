<template>
  <a
    :href="
      videosItem.infoType === 'audio'
        ? '/mobVideoCenterAudioDel?audioId=' + videosItem.infoId
        : videosItem.infoType === 'article'
        ? '/mobVideoCenterArticleDel?articleId=' + videosItem.infoId
        : '/mobVideoCenterDel?videoId=' + videosItem.infoId
    "
  >
    <div
      class="videos-item"
      @mouseover="mouseOver()"
      @mouseleave="mouseLeave()"
    >
      <div class="video">
        <img
          :src="
            videosItem.backImg ? videosItem.backImg : imgbg[videosItem.infoType]
          "
          alt=""
          :style="{
            height: types[videosItem.infoType] != '视频' ? 'unset' : 100 + '%',
            'margin-top':
              types[videosItem.infoType] != '视频' ? 12 + 'px' : 'unset',
          }"
        />
        <div class="videotime" v-if="types[videosItem.infoType] != '文章'">
          <span class="time">{{ duration }}</span>
        </div>
        <div :class="{ mask: isactive }"></div>
        <div :class="{ maskicon: isactive }">
          <img
            :src="imgstatus"
            :style="{ display: this.isactive ? 'block' : 'none' }"
          />
        </div>
      </div>
      <el-row
        style="margin:0 13px; margin-top:10px; border-bottom:1px solid #F5F5F5"
      >
        <el-row style="margin-bottom:2px">
          <el-col>
            <div class="videos-info">{{ videosItem.title }}</div>
          </el-col>
        </el-row>
        <el-row style="margin-bottom:12px">
          <el-col :span="20">
            <span class="frequency">{{ videosItem.browse }}次播放</span>
          </el-col>
          <el-col :span="4">
            <!-- <div class="stype" > -->
            <span class="type" :style="tycolor">{{
              types[videosItem.infoType]
            }}</span>
            <!-- </div> -->
          </el-col>
        </el-row>
      </el-row>
      <el-row style="margin:0 13px; margin-top:10px;" type="flex">
        <el-col style="display: flex; align-item:center">
          <div>
            <el-avatar :size="24" :src="videosItem.avatar"></el-avatar>
          </div>
          <div class="name">{{ videosItem.doctorName }}</div>
        </el-col>
        <el-col style="text-align: right">
          <span class="position">{{
            videosItem.doctorTitle.length > 8
              ? videosItem.doctorTitle.slice(0, 7) + "..."
              : videosItem.doctorTitle
          }}</span>
        </el-col>
      </el-row>
    </div>
  </a>
</template>

<script>
import stohs from "@/util/s_to_hs";
// import { Encrypt } from '@/util/EncryptionToDecrypt'
export default {
  name: "VideosListItemtest",
  data() {
    return {
      isactive: false,
      imgstatus: require("assets/zhuangtai/p1.svg"),
      duration: "",
      types: {
        audio: "音频",
        video: "视频",
        qa: "问答",
        article: "文章",
      },
      videotype: "",
      imgbg: {
        audio: require("../../../assets/cover/audio.png"),
        video: require("../../../assets/cover/video.png"),
        article: require("../../../assets/cover/article.png"),
      },
    };
  },
  props: {
    videosItem: {
      type: Object,
      default() {
        return {};
      },
    },
    videosIndex: {
      type: Number,
    },
    stype: {
      type: String,
    },
  },
  computed: {
    // asd () {
    //   if (this.videosItem.infoType === 'audio') {
    //     return { height: '90px' }
    //   }
    // },
    showImage() {
      return (
        this.videosItem.img || this.videosItem.image || this.videosItem.show.img
      );
    },
    tycolor() {
      if (this.videosItem.infoType === "video") {
        return { color: "#4263EB", border: "1px solid #4263EB" };
      } else if (this.videosItem.infoType === "audio") {
        return { color: "#F6A609", border: "1px solid #F6A609" };
      } else if (this.videosItem.infoType === "article") {
        return { color: "#595959", border: "1px solid #595959" };
      } else {
        return {};
      }
    },
  },
  mounted() {
    // // console.log(this.videosItem + 'videosItem')
    this.duration = stohs(this.videosItem.duration);
    // // console.log(this.videotype, 'videotyp----------')
  },
  watch: {
    videosItem: function(newVal) {
      this.duration = stohs(this.videosItem.duration);
    },
    immediate: true,
    deep: true,
  },
  methods: {
    // itemClick (e) {
    //   if (this.videosItem.infoType === 'audio') {
    //     // this.$router.push('/voicedetail/' + 1)
    //     // sessionStorage.setItem('nav', '3')
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterAudioDel' })
    //     this.$router.push({ path: '/mobVideoCenterAudioDel', query: { audioId: e } })
    //   }
    //   if (this.videosItem.infoType === 'article') {
    //     // this.$router.push('/articledetail/' + 1)
    //     // sessionStorage.setItem('nav', '4')
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterArticleDel' })
    //     this.$router.push({ path: '/mobVideoCenterArticleDel', query: { articleId: e } })
    //   }
    //   if (this.videosItem.infoType === 'video') {
    //     // this.$router.push('/videodetail/' + 1)
    //     // sessionStorage.setItem('nav', '2')
    //     // this.$router.push({ path: '/mobVideoCenterDel', query: { i: Encrypt(e) } })
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterDel' })
    //     this.$router.push({ path: '/mobVideoCenterDel', query: { videoId: e } })
    //   }
    // },
    mouseOver() {
      this.isactive = true;
      if (this.videosItem.infoType === "video") {
        this.imgstatus = require("assets/zhuangtai/p1.svg");
      }
      if (this.videosItem.infoType === "audio") {
        this.imgstatus = require("assets/zhuangtai/y1.svg");
      }
      if (this.videosItem.infoType === "article") {
        this.imgstatus = require("assets/zhuangtai/w1.svg");
      }
    },
    // 移出
    mouseLeave() {
      this.isactive = false;
    },
  },
};
</script>

<style scoped>
.videos-item {
  width: 220px;
  height: 216px;
  border: 1px solid #f5f5f5;
  border-radius: 3px;
  overflow: hidden;
}
.video {
  position: relative;
  width: 220px;
  height: 110px;
  border-radius: 3px 3px 0 0;
}
::v-deep .el-avatar > img {
  height: unset;
  width: 100%;
}
.mask {
  width: 220px;
  height: 110px;
  top: 0;
  border-radius: 3px 3px 0 0;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
}
.video img {
  width: 100%;
  /* height: 100%; */
  /* margin-top: 10px; */
  border-radius: 3px 3px 0 0;
}
a {
  color: #262626;
  text-decoration: none;
}
.videotime {
  position: absolute;
  bottom: 5px;
  right: 8px;
  background: #262626;
  border-radius: 4px;
  opacity: 0.7;
}
.videotime .time {
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  padding: 1px 4px;
}
.videos-info {
  width: 200px;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.frequency {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 20px;
}
.stype {
  border-radius: 2px;
}
.type {
  font-size: 10px;
  text-align: center;
  padding: 0.5px 1.5px;
  border-radius: 4px;
}
.maskicon img {
  position: absolute;
  width: 36px;
  height: 36px;
  left: 50%;
  top: 40%;
  transform: translateX(-50%);
  /* border-radius: 3px 1px 0px 0px; */
}
.name {
  margin-top: 4px;
  margin-left: 8px;
  color: #262626;
  font-size: 12px;
  /* line-height: 20px; */
}
.position {
  color: #8c8c8c;
  font-size: 12px;
  line-height: 20px;
}
</style>
